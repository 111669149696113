import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Seo } from '../components/seo'
import { VCard } from '../components/vcard'

const VCardPage = ({ pageContext }) => {
    var { partner } = pageContext
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    domain
                    menuLinks {
                        name
                        type
                        link
                    }
                }
            }
        }
    `)
    if (!partner)
        partner = {
            name: '',
            position: 'Individuelle Software-Lösungen und Consulting',
            job: '',
            phone: '',
            key: 'ask',
        }

    return (
        <>
            <Seo title={'vcard'} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                    height: '100vh',
                }}
            >
                <VCard
                    name={partner.name}
                    position={partner.position}
                    job={partner.job}
                    phone={partner.phone}
                    email={`${partner.key}@${data.site.siteMetadata.domain}`}
                    identifier={partner.key}
                />
            </div>
        </>
    )
}

export default VCardPage
