import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { useQueryParams } from '../hooks/use-queryparams'
import * as styles from './vcard.module.css'

export interface VCardProps {
    name: { first: string; last: string }
    position: string
    job: string
    phone: string
    email: string
    icon?: string
    identifier: string
}

const VCard: React.FC<VCardProps> = ({
    name,
    position,
    job,
    phone,
    email,
    icon,
    identifier,
}) => {
    const params = useQueryParams()
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    siteUrl
                    domain
                    contact {
                        firm
                        name
                        legalForm
                        co
                        street
                        zip
                        city
                    }
                }
            }
        }
    `)

    const download = () => {
        const vcard = new Blob(
            [
                `
BEGIN:VCARD
VERSION:4.0
${name ? `N;CHARSET=UTF-8:${name.last};${name.first};;;` : ''}
FN;CHARSET=UTF-8: ${
                    name
                        ? `${name.first} ${name.last}`
                        : data.site.siteMetadata.contact.firm
                }
ORG;CHARSET=UTF-8:${data.site.siteMetadata.contact.firm};${position}
TITLE;CHARSET=UTF-8:${job}
URL;WORK:${data.site.siteMetadata.siteUrl}
EMAIL;type=WORK;type=PREF:${email}
TEL;type=CELL;MOBILE:${phone.replace(/ /g, '')}
ADR;CHARSET=UTF-8;WORK;type=pref:;;${data.site.siteMetadata.contact.street};${
                    data.site.siteMetadata.contact.city
                };;${data.site.siteMetadata.contact.zip};GERMANY
LABEL;WORK;PREF;ENCODING=QUOTED-PRINTABLE;CHARSET=UTF-8:${
                    data.site.siteMetadata.contact.street
                }=0D=0A=
${data.site.siteMetadata.contact.zip} ${
                    data.site.siteMetadata.contact.city
                }=0D=0A=
GERMANY
REV:${new Date().toISOString()}
END:VCARD
`,
            ],
            { type: 'text/vcard' }
        )
        window.open(URL.createObjectURL(vcard), '_blank')
    }

    return (
        <div className={styles.card}>
            <div className={styles.contact}>
                <h1>
                    <b>{data.site.siteMetadata.contact.name}</b>{' '}
                    {data.site.siteMetadata.contact.legalForm}
                    <br />
                    <small>{data.site.siteMetadata.contact.co}</small>
                </h1>
                <div className={styles.person}>
                    {name && (
                        <h2>
                            {name.first} {name.last}
                        </h2>
                    )}
                    <span>
                        {position}
                        {position && job ? ' | ' : ''}
                        {job}
                    </span>
                </div>
                <div className={styles.address}>
                    {email && <a href={`mailto:${email}`}>{email}</a>}
                    {email && phone ? <br /> : null}
                    {phone && (
                        <a href={`tel:${phone.replace(/ /g, '')}`}>{phone}</a>
                    )}
                </div>
            </div>
            <div className={styles.side}>
                <div className={styles.logo}>
                    <img src="/images/icon-in-circle.png" />
                </div>
                <a
                    href={`${
                        data.site.siteMetadata.siteUrl
                    }?utm_medium=vcard&utm_source=${identifier}${
                        params?.get('utm_campaign')
                            ? `&utm_campaign=${params.get('utm_campaign')}`
                            : ''
                    }`}
                    className={styles.website}
                >
                    {data.site.siteMetadata.domain}
                </a>
                <button className={styles.download} onClick={download}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        viewBox="0 0 24 24"
                    >
                        <g>
                            <rect fill="none" height="24" width="24" />
                        </g>
                        <g>
                            <path d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z" />
                        </g>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export { VCard }
