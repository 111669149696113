import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet, HelmetProps } from 'react-helmet'

export interface SeoProps {
    title: string
    description?: string
    robots?: string
    meta?: HelmetProps['meta']
}

export const Seo: React.FC<SeoProps> = ({ title, description, robots, meta }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    const pageMeta = [
        {
            name: `description`,
            content: description,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: description,
        },
        {
            name: `robots`,
            content: robots,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        ...(meta || []),
    ]

    return (
        <Helmet
            title={`${data.site.siteMetadata.title} | ${title}`}
            meta={pageMeta}
        />
    )
}

Seo.defaultProps = {
    meta: [],
    description: ``,
    robots: 'all',
}
